.banner {
  width: 100%;
  height: 40em;
  overflow: hidden;
  background-size: cover;
}

.bannerImage {
  object-fit: fill;
}

.bannerTitleContainer {
  position: absolute;
  width: 100%;
  animation: fade-in 2s;
}

.bannerTitle {
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  color: white;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 20em;
  font-size: 16pt;
  animation: slide-left 2s;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3em;
  animation: fade-in 2s;
}

.nick {
  opacity: 0.5;
  margin-left: 1em;
}

.name {
  margin-right: 1em;
}

.titleContainer {
  display: flex;
}

.aboutText {
  text-align: center;
  padding: 1.5em;
  margin: 0 20%;
}

@media screen and (max-width: 1024px) {
  .bannerTitle {
    padding: 0 1em;
  }

  .aboutText {
    margin: 0 10%;
  }
}

@media screen and (max-width: 640px) {
  .bannerTitle {
    padding: 0 1em;
  }

  .aboutContainer {
    padding: 3em 0;
  }

  .aboutText {
    margin: 0;
  }

  .titleContainer {
    flex-direction: column;
  }

  .nick {
    margin: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-left {
  0% {
    margin-left: 2em;
  }

  100% {
  }
}
