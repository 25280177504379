.logo {
  width: 7em;
  max-height: 1.4em;
  cursor: pointer;
}

.logoDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.changingText {
  margin: 0 2em;
  padding: 0;
  opacity: 0.5;
  animation: fade-in 1s;
  font-family: "Dancing Script", cursive;
}

.navButtons {
  color: rgb(95, 95, 95);
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.switchLanguage {
  display: flex;
  margin-top: 0.4em;
}

.bra {
  margin-right: 0.5em;
  cursor: pointer;
}

.eng {
  margin-left: 0.5em;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .logo {
    width: 5em;
    max-height: 1em;
  }
  .changingText {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .logo {
    width: 5em;
    max-height: 1em;
  }

  .changingText {
    display: none;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}
