.portfolioContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6em;
}

.toggle {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 4em;
}

.active {
  margin: 0 0.5em;
  cursor: pointer;
}

.passive {
  opacity: 0.5;
  margin: 0 0.5em;
  cursor: pointer;
}

.portItems {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 6em;
  animation: fade-in 1s;
}

.portCard {
  width: 100%;
  border-left: 1px solid;
  padding: 1em;
  animation: slide-left 1s;
}

.portCard2 {
  margin-top: 5em;
  margin-bottom: 5em;
  width: 100%;
  border-left: 1px solid;
  padding: 1em;
  animation: slide-left 1s;
}

.portImage {
  margin: 1em 0;
  width: 100%;
}

.portTitle {
  display: flex;
  align-items: center;
}

.portContent {
  opacity: 0.8;
}

@media screen and (max-width: 1650px) {
  .portfolioContainer {
    padding: 4em;
  }

  .portItems {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4em;
  }
}

@media screen and (max-width: 1024px) {
  .portfolioContainer {
    padding: 2em;
  }

  .portItems {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;
  }
}

@media screen and (max-width: 640px) {
  .portfolioContainer {
    padding: 1em;
  }

  .portItems {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-left {
  0% {
    margin-left: 1em;
  }

  100% {
  }
}
