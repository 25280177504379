.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerContact {
  width: 75%;
  padding-top: 5em;
  border-top: 1px solid;
  text-align: center;
}

.profilePic {
  width: 7.5em;
  margin-bottom: 3em;
}

.footerSocial {
  margin-bottom: 5em;
}

.footerItem {
  margin: 0.5em;
  cursor: pointer;
}

.instagram {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.insImage {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .instagram {
    grid-template-columns: repeat(3, 1fr);
  }

  .logo {
    width: 5em;
  }
}

@media screen and (max-width: 640px) {
  .instagram {
    grid-template-columns: repeat(1, 1fr);
  }

  .logo {
    width: 5em;
  }
}
