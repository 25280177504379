.nav-link {
  font-size: 10pt;
  margin: 0 1em;
  font-family: "Montserrat", sans-serif;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(95, 95, 95);
}

.navbar {
  padding: 1em 3em;
}

@media screen and (max-width: 1024px) {
  .navbar {
    padding: 1em 1em;
  }
}

@media screen and (max-width: 640px) {
  .navbar {
    padding: 0.5em 0.5em;
  }
}

a,
a:hover {
  text-decoration: none;
  color: black;
}

a:hover {
  opacity: 0.5;
}
